import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b077406a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar__top" }
const _hoisted_2 = { class: "sidebar__management" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  class: "sidebar__icon",
  "aria-label": "settings",
  role: "button"
}
const _hoisted_6 = { class: "heading heading--regular heading--white threeLineText" }
const _hoisted_7 = { class: "sidebar__text threeLineText" }
const _hoisted_8 = { class: "sidebar__footer" }
const _hoisted_9 = {
  key: 0,
  class: "sidebar__bottom session-code"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_SessionCode = _resolveComponent("SessionCode")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TutorialStep = _resolveComponent("TutorialStep")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_scrollbar, {
      class: "sidebar",
      height: "100%",
      "max-height": "var(--app-height)"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_container, { class: "sidebar__container" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_SidebarHeader),
                  _createElementVNode("div", _hoisted_2, [
                    (!!_ctx.$slots.management)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _renderSlot(_ctx.$slots, "management", {}, undefined, true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (_ctx.showBack)
                            ? (_openBlock(), _createBlock(_component_el_page_header, {
                                key: 0,
                                content: _ctx.currentRouteTitle,
                                title: _ctx.$t('general.back'),
                                onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
                              }, null, 8, ["content", "title"]))
                            : _createCommentVNode("", true)
                        ])),
                    _createElementVNode("div", _hoisted_5, [
                      _renderSlot(_ctx.$slots, "settings", {}, undefined, true),
                      (_ctx.canModify)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('openSettings', $event)))
                          }, [
                            _createVNode(_component_ToolTip, {
                              effect: 'light',
                              text: _ctx.$t('moderator.organism.settings.topicSettings.edit')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_font_awesome_icon, {
                                  class: "awesome-icon",
                                  icon: "cog"
                                })
                              ]),
                              _: 1
                            }, 8, ["text"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.canModify)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('delete', $event)))
                          }, [
                            _createVNode(_component_ToolTip, {
                              effect: 'light',
                              text: _ctx.$t('moderator.organism.settings.topicSettings.delete')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_font_awesome_icon, {
                                  class: "awesome-icon",
                                  icon: "trash"
                                })
                              ]),
                              _: 1
                            }, 8, ["text"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.title), 1),
                  _createVNode(_component_el_divider),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.description), 1),
                  _renderSlot(_ctx.$slots, "headerContent", {}, undefined, true)
                ])
              ]),
              _: 3
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "mainContent", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    }),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.session)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_SessionCode, {
              code: _ctx.session.connectionKey
            }, null, 8, ["code"]),
            _createVNode(_component_TutorialStep, {
              type: "sessionDetails",
              step: "publicScreen",
              order: 3
            }, {
              default: _withCtx(() => [
                (_ctx.session.id)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/public-screen/${_ctx.session.id}`,
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, { type: "info" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: ['fac', 'presentation'] })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}